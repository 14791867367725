@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

/* testing testing testing testing */

.App {
  font-family: 'Ubuntu', sans-serif;
  color: #d3dfea;
  text-align: center;
  background-color: black;
}
.navbar {
  display: flex;
  justify-content: space-between;
  /* max-width: 100%; */
  padding-top: 20px;
  padding-left: 80px;
  padding-right: 80px;
}
.navLogo {
  height: 80px;
}

.navLinks {
  display: flex;
  list-style: none; 
}

.navLink {
  font-size: 14px;
  margin: 15px;
  
}

.main-container {
  min-height:100vh;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s .5s forwards;
}


.mainLogo {
  max-height: 250px;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .mainLogo {
      animation: App-logo-spin infinite 10s linear;
  }
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.projects {
  padding: 50px
}

.projectList {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  flex-wrap: wrap;
  /* border: 1px orange solid; */
}

.project {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 50%;
  /* min-width: 400px; */
  /* border: 1px blue solid; */
}

.projectImg {
  max-height: 240px;
  height: auto;
  width: auto;
  padding: 15px
}

/* mobile */
@media (max-width: 1380px){
  .projectList {
    flex-direction: column;
  }
  .project {
    width: 100%;
  }
  .projectImg {
    height: 200px;
    width: 265px;
  }
  
}

@media (max-width: 700px){

  .project {
    flex-direction: column;
    padding-bottom: 15px;
    width: 95%;
    margin: auto
  }
  .projectImg {
    max-width: 300px;
    height: auto;
    width: auto;
    padding: 0px;
    margin: auto

  }
  
}

a {
  color: #d3dfea;
}

a::after {
  color: #d3dfea
}

button {
  outline : 0;
  -moz-outline : 0;
  font-size: 14px;
  margin-top: 50px;
  border-radius: 10px;
  border-color: #d3dfea;
  background-color: black;
  color: #d3dfea;

}

button:hover {
  border-color: black;
  background-color: #d3dfea;
  color: black
}


@keyframes fadeIn {
  0% {opacity: 0;}
  5% {visibility: visible;}
  100% {opacity: 1; visibility: visible;}
} 
